// @flow

import * as React from 'react';
import classnames from 'classnames';
import { Map, WMSTileLayer } from 'react-leaflet';
import Control from 'react-leaflet-control';
import { Icon } from 'components';
import polyglot from 'i18n';

import styles from './MapLayout.css';

type Props = {
  fullscreen: boolean,
  exitFullscreen(): void,
  wide: boolean,
  tall: boolean,
  search: boolean,
  mapCenter: Array<number>,
  mapRef: string,
  enterFullscreen(): void,
  panTo(): void,
  children: any,
};

const MapLayout = ({
  fullscreen,
  exitFullscreen,
  wide,
  tall,
  search,
  mapRef,
  enterFullscreen,
  panTo,
  lokale,
  children,
  ...props
}: Props) => {
  // $FlowFixMe
  const classes = classnames(styles.wrapper, {
    [styles.wide]: wide && !fullscreen,
    [styles.fullscreen]: fullscreen,
    [styles.tall]: tall,
    [styles.search]: search,
    [styles.lokale]: lokale,
  });
  return (
    <div
      onMouseUp={exitFullscreen}
      role="button"
      id="wrapper"
      tabIndex={0}
      className={classes}
    >
      <Map
        className={styles.map}
        aria-label={polyglot.t('mapLayout.kartLabel')}
        ref={mapRef}
        {...props.viewport}
      >
        <WMSTileLayer
          url="https://{s}.geonorge.no/skwms1/wms.topo"
          subdomains={['wms']}
          layers="topo"
          format="image/png"
          attribution="Kartverket"
        />
        <Control position="topleft">
          <button
            className={styles.button}
            data-cy="fullskjerm"
            aria-label="Fullskjerm"
            type="button"
            onClick={enterFullscreen}
          >
            <Icon icon="fullscreen" size="2" noMargin />
          </button>
        </Control>
        <Control position="topleft">
          <button
            className={styles.button}
            type="button"
            data-cy="plassering"
            aria-label={polyglot.t('mapLayout.hoppPlassering')}
            onClick={panTo}
          >
            <Icon icon="location-pin" size="2" noMargin />
          </button>
        </Control>
        {children}
      </Map>
    </div>
  );
};

export default MapLayout;
